.parcialEstatus {
    color: #ff8e26;
    border-bottom: 1px dotted #031851;
    width: 50px;
    font-weight: 600;
}

.failedEstatus {
    color: #ff5968;
    border-bottom: 1px dotted #031851;
    width: 50px;
    font-weight: 600;
}