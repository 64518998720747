.accessButton{
    padding-left: 24px;
    padding-right: 24px;
    margin-left: 16px;
    border-radius: 20px;
    background-color: #2F3190;
}

.accessButton:hover{
    background-color: #2f3190;
    color: #fff;
}

.accessButton:disabled{
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
}

.bankDiv {
    padding-bottom: 16px;
    padding-top: 16px; 
    display: flex; 
    align-items: center;
}