.buttonShowChanges {
    margin-left: 30px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 40px;
    color: #2F3190
}

.buttonActive {
    margin-left: 30px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 40px;
    background-color: #2F3190;
    color: white
}

.changesText {
    color: #031851B2;
    align-items: center;
    display: flex;
    padding-left: 30px;
}

.container {
    display: flex;
    align-items: center;
}

.icon {
    color: #031851B2;
    margin-left: 10px;
}
